import { req } from '@/api';
import { defineComponent, reactive, Ref, ref } from '@vue/composition-api';

import VideoPlayer from '@/components/VideoPlayer/index.vue';

import SvgChevronUp from './svg/chevron-up';
import SvgChevronDown from './svg/chevron-down';
import SvgLove from './svg/love';
import SvgComment from './svg/comment';
import SvgShare from './svg/share';

import { VueConstructor } from 'vue';

import { parseTime } from '@/public/methods';

interface InteractiveBtns {
	readonly key: string | number;
	readonly title: string;
	readonly svg: VueConstructor<Vue>;
	count: number | (() => number);
	click?: () => void;
}

interface InfoObj {
	title: string;
	praiseCount: number;
	commentCount: number;
	shareCount: number;
	author?: string;
	authorUrl?: string;
	avatar?: string;
	time?: number;
}

const titleMaxLength = 80;
const formatNumber = (num: number): string => {
	return num >= 10000 ? Math.floor(num / 1000) / 10 + '万' : num.toString();
};

export default defineComponent({
	components: {
		VideoPlayer,
		SvgChevronUp,
		SvgChevronDown,
		SvgLove,
		SvgComment,
		SvgShare,
	},
	setup(props, { root }: any) {
		const { $route, $message, $modal } = root;
		const isLoading: Ref<boolean> = ref(true);
		const isError: Ref<boolean> = ref(false);
		const videoUrl = ref('');
		const directive = ref(''); //向播放器发送指令
		const info: Ref<InfoObj> = ref({
			title: '',
			praiseCount: 0,
			commentCount: 0,
			shareCount: 0,
		});
		req('PHOTO_SHORTVIDEO_DETAIL', { onlyid: $route?.params?.onlyid || '' })
			.then((data: any) => {
				videoUrl.value = data.video;
				info.value = Object.assign(info.value, { ...data });
				//console.log(data);
			})
			.catch(error => {
				$message.error(error);
				isError.value = true;
			})
			.finally(() => {
				isLoading.value = false;
			});
		//功能块：视频的播放与暂停（需向子组件通讯）
		let timerForPp: number | undefined = undefined;
		let preventPp = false;
		const ppVideo = () => {
			if (preventPp) {
				clearTimeout(timerForPp);
				return;
			}
			preventPp = true;
			timerForPp = setTimeout(() => {
				preventPp = false;
				directive.value = directive.value === 'pause' ? 'play' : 'pause';
			}, 300);
		};
		//功能块：视频喜欢，并向服务器端发送
		const isLoved: Ref<boolean> = ref(false);
		const loveVideo = () => {
			//alert('视频喜欢+1');
			isLoved.value = true;
			info.value.praiseCount++;
			req('PHOTO_SHORTVIDEO_LOVE', {
				onlyid: $route?.params?.onlyid || '',
			})
				.then(() => {
					$message.success('感谢喜欢！');
				})
				.catch(error => {
					$message.error('' + error);
					isLoved.value = false;
					info.value.praiseCount--;
				});
			preventPp = false;
		};
		//功能块：文字展开与收起民
		const isTextUnfold: Ref<boolean> = ref(false);
		const showTitle = (text: string): string => {
			const max = titleMaxLength;
			if (text.length > max) {
				if (!isTextUnfold.value) {
					return text.substring(0, max) + '...';
				} else {
					return text;
				}
			} else {
				return text;
			}
		};
		//功能块：互动
		const interactiveBtns: Ref<InteractiveBtns[]> = ref([
			{
				key: 'praise',
				title: '喜欢',
				svg: SvgLove,
				count: () => (typeof info.value.praiseCount === 'number' ? info.value.praiseCount : 0),
				click: () => {
					loveVideo();
				},
			},
			{
				key: 'comment',
				title: '评论',
				svg: SvgComment,
				count: () => (typeof info.value.commentCount === 'number' ? info.value.commentCount : 0),
				click: () => {
					$modal('暂不支持评论');
				},
			},
			{
				key: 'share',
				title: '分享',
				svg: SvgShare,
				count: () => (typeof info.value.shareCount === 'number' ? info.value.shareCount : 0),
				click: () => {
					$modal('暂不支持分享');
				},
			},
		]);
		return {
			isLoading,
			isError,
			videoUrl,
			directive,
			ppVideo,
			loveVideo,
			info,
			isLoved,
			interactiveBtns,
			formatNumber,
			isTextUnfold,
			showTitle,
			titleMaxLength,
			parseTime,
		};
	},
});
