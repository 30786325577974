import Play from './play';
import Pause from './pause';
import Maximize from './maximize';
import Minimize from './minimize';
import Volume from './volume';
import VolumeLite from './volume-lite';
import VolumeMute from './volume-mute';

export const SvgPlay = Play;
export const SvgPause = Pause;
export const SvgMaximize = Maximize;
export const SvgMinimize = Minimize;
export const SvgVolume = Volume;
export const SvgVolumeMute = VolumeMute;
export const SvgVolumeLite = VolumeLite;
