import { defineComponent } from '@vue/composition-api';

import Card from '@/components/Card';
import ShortvideoDetail from '@/components/Article/ShortvideoDetail';

export default defineComponent({
    components: { ShortvideoDetail, Card },
    setup() {
        return {};
    },
});
