import {
    Data,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    reactive,
    ref,
    watch,
} from '@vue/composition-api';
import type { Ref, ComponentInstance } from '@vue/composition-api';

import store from '@/store';

import {
    SvgPlay,
    SvgPause,
    SvgMaximize,
    SvgMinimize,
    SvgVolume,
    SvgVolumeLite,
    SvgVolumeMute,
} from './svg';
import dealUrl from './deal-url.ts';
import { VueConstructor } from 'vue';
//import { PresetStatusColorTypes } from 'antd/lib/_util/colors';

import TemplateVolume from './template-volume';
import TemplateSpeed from './template-speed';
import speedList from './speed-list';

interface ExtraButton {
    operate: string;
    text: string | (() => string);
    icon: string | VueConstructor<Vue> | (() => VueConstructor<Vue>);
    click?: () => void | boolean | Promise<void>;
    mouseenter?: () => void;
    mouseleave?: () => void;
    showText?: () => string;
    childEvent?: (event: any, thing: string) => void;
    childTemplate?: VueConstructor<Vue> | (() => VueConstructor<Vue>);
}
interface Status {
    playState: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 1001;
    isPlaying: boolean;
    isBuffering: boolean;
    isSeeking: boolean;
    currentTime: number;
    totalTime: number;
    isFullscreen: boolean;
    volume: number;
    isMute: boolean;
    speed: number;
    isRemainingTime: boolean;
}

export default defineComponent({
    components: {
        SvgPlay,
        SvgPause,
        SvgMaximize,
        SvgMinimize,
        SvgVolume,
        SvgVolumeLite,
        SvgVolumeMute,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        src: {
            type: String,
            default: '',
        },
        width: {
            type: [String, Number],
            default: 'auto',
        },
        height: {
            type: [String, Number],
            default: 'auto',
        },
        directive: {
            //父组件传递过来的指令
            type: String,
            default: '',
        },
    },
    emits: ['doubleclick'],
    setup(props, { root, emit }: any) {
        const { $message } = root;
        const playerHash: string = Math.floor(
            Math.random() * 16777215
        ).toString(16);
        const componentDOM: Ref<null | any> = ref(null);
        const videoUrl: Ref<string> = ref('');
        watch(
            () => props.src,
            (value: string) => (videoUrl.value = value),
            { immediate: true }
        );
        const videoDOM: Ref<null | HTMLVideoElement> = ref(null);
        //监听播放器是否就绪
        const isReady: Ref<boolean> = ref(false);
        const isFirstPlay: Ref<boolean> = ref(false);
        const isError: Ref<boolean> = ref(false);
        const status = reactive(<Status>{
            playState: 0, //0未知1停止2暂停3播放4快进5快退6缓冲7等等8完毕9切换10就绪1001出错
            isPlaying: false,
            isBuffering: false, //正在缓冲
            isSeeking: false, //正在加载
            currentTime: 0,
            totalTime: -1,
            isFullscreen: false, //是否全屏
            volume: 1,
            isMute: false, //是否静音
            speed: 1,
            isRemainingTime: false,
        });
        //播放器的基本控制
        const control = (
            operate: string,
            options: any | undefined = undefined
        ): void | boolean | Promise<void> => {
            if (!isReady.value) {
                $message.error('播放器未就绪');
                return;
            }
            switch (operate) {
                case 'play': {
                    const playPromise = videoDOM.value?.play();
                    status.playState = 3;
                    status.isPlaying = true;
                    playPromise?.catch(error => {
                        status.playState = 1;
                        status.isPlaying = false;
                        if (('' + error).includes('no supported')) {
                            $message.error('播放器错误或视频不被支持');
                            console.warn('' + error);
                        } else {
                            $message.warning('视频自动播放被浏览器阻止');
                        }
                    });
                    //emit('doubleclick');
                    return playPromise;
                    break;
                }
                case 'pause': {
                    try {
                        videoDOM.value?.pause();
                        status.playState = 1;
                        status.isPlaying = false;
                    } catch (e) {
                        //
                    }
                    break;
                }
                case 'pp': {
                    if (status.isPlaying) {
                        control('pause');
                    } else {
                        control('play');
                    }
                    break;
                }
                case 'toggleFullscreen': {
                    //const dom = videoDOM.value?.parentNode?.parentNode;
                    const dom = componentDOM.value;
                    dom?.setAttribute('allowfullscreen', 'true');
                    if (status.isFullscreen) {
                        const dom: any = document;
                        try {
                            dom?.exitFullscreen()?.catch(() => {
                                //
                            });
                        } catch (e) {
                            try {
                                dom?.mozCancelFullScreen()?.catch(() => {
                                    //
                                });
                            } catch (e) {
                                try {
                                    dom?.msExitFullscreen()?.catch(() => {
                                        //
                                    });
                                } catch (e) {
                                    try {
                                        dom?.webkitExitFullscreen()?.catch(
                                            () => {
                                                //
                                            }
                                        );
                                    } catch (e) {
                                        alert(
                                            '操作失败,请按键盘Esc键手动退出全屏'
                                        );
                                    }
                                }
                            }
                        }
                    } else {
                        try {
                            dom?.requestFullscreen();
                        } catch (e) {
                            try {
                                dom?.mozRequestFullScreen();
                            } catch (e) {
                                try {
                                    dom?.msRequestFullscreen();
                                } catch (e) {
                                    try {
                                        dom?.webkitRequestFullScreen();
                                    } catch (e) {
                                        try {
                                            dom?.oRequestFullscreen();
                                        } catch (e) {
                                            alert('无法进入全屏');
                                        }
                                    }
                                }
                            }
                        }
                    }
                    //status.isFullscreen = !status.isFullscreen;
                    break;
                }
                case 'toggleMute': {
                    //console.log('调整');
                    if (videoDOM === null) {
                        return;
                    }
                    if (status.isMute) {
                        (videoDOM.value as HTMLVideoElement).muted = false;
                    } else {
                        (videoDOM.value as HTMLVideoElement).muted = true;
                    }
                    status.isMute = !status.isMute;
                    break;
                }
                case 'speed': {
                    const speedLevel: number[] = Array.isArray(speedList)
                        ? speedList
                        : [0.5, 0.8, 1.0, 1.2, 1.5, 2];
                    let setSpeed: number | undefined = undefined;
                    if (typeof options === 'number') {
                        setSpeed = options;
                    }
                    if (!setSpeed || !speedLevel.includes(setSpeed)) {
                        let nowSpeed = videoDOM.value?.playbackRate;
                        if (
                            typeof nowSpeed === 'undefined' ||
                            !speedLevel.includes(nowSpeed)
                        ) {
                            nowSpeed = 1;
                        }
                        const findSpeedIndex = speedLevel.findIndex(
                            item => item.toFixed(1) === nowSpeed?.toFixed(1)
                        );
                        const setSpeedIndex =
                            findSpeedIndex + 1 >= speedLevel.length
                                ? 0
                                : findSpeedIndex + 1;
                        if (!speedLevel[setSpeedIndex]) {
                            setSpeed = 1;
                        } else {
                            setSpeed = speedLevel[setSpeedIndex];
                        }
                    }
                    try {
                        (videoDOM.value as any).playbackRate = setSpeed;
                        status.speed = setSpeed;
                    } catch (e) {
                        status.speed = 1;
                    }
                    break;
                }
                case 'gototime': {
                    if (typeof options !== 'number') {
                        return;
                    }
                    try {
                        (videoDOM.value as any).currentTime = options;
                        control('play');
                    } catch (e) {
                        //
                    }
                    break;
                }
                case 'volume': {
                    if (typeof options === 'number') {
                        options = options < 0 ? 0 : options > 1 ? 1 : options;
                        (videoDOM.value as any).volume = options;
                        status.volume = options;
                    }
                    break;
                }
                default: {
                    alert('无效的操作');
                }
            }
        };
        //播放器页面的单击和双击
        const maskClick = (() => {
            let timer: number | undefined = undefined;
            let isDoubleClick = false;
            return () => {
                if (isDoubleClick) {
                    emit('doubleclick');
                    clearInterval(timer);
                    isDoubleClick = false;
                    return;
                }
                isDoubleClick = true;
                timer = setTimeout(() => {
                    control('pp');
                    isDoubleClick = false;
                }, 300);
            };
        })();
        //播放器的时间格式化
        const formatTime = (num: number): string => {
            const remainingStr = num < 0 ? '-' : '';
            num = Math.abs(num);
            return `${remainingStr}${('' + Math.floor(num / 60)).padStart(
                2,
                '0'
            )}:${('' + Math.floor(num % 60)).padStart(2, '0')}`;
        };

        //播放器的初始化与卸载
        const init = () => {
            unistall();
            isReady.value = false;
            new Promise((resolve, reject) => {
                if (videoDOM.value) {
                    setTimeout(() => {
                        resolve('ok');
                    }, 1);
                } else {
                    reject('无法初始化播放器');
                }
            })
                .then(() => {
                    const Obj = videoDOM.value;
                    Obj?.setAttribute('preload', 'true');
                    Obj?.setAttribute('autoplay', 'true');
                    Obj?.setAttribute('loop', 'true');
                    Obj?.addEventListener('play', () => {
                        status.isPlaying = true;
                        status.playState = 3;
                    });
                    Obj?.addEventListener('pause', () => {
                        status.isPlaying = false;
                        status.playState = 2;
                    });
                    Obj?.addEventListener('oncanplay', () => {
                        status.playState = 10;
                        control('play');
                        isReady.value = true;
                        //console.log('就绪');
                    });
                    Obj?.addEventListener('error', () => {
                        status.isPlaying = false;
                        status.playState = 1001;
                    });
                    Obj?.addEventListener('loadeddata', () => {
                        if (Obj && Obj.duration) {
                            status.totalTime = isNaN(Obj.duration)
                                ? -1
                                : Obj.duration;
                        }
                        isFirstPlay.value = true;
                    });
                    Obj?.addEventListener('timeupdate', () => {
                        status.currentTime =
                            isNaN(Obj.currentTime) || !Obj?.currentTime
                                ? 0
                                : Obj.currentTime;
                        status.totalTime =
                            isNaN(Obj.duration) || !Obj?.duration
                                ? -1
                                : Obj.duration;
                    });
                    //将URL写入
                    //videoUrl.value = '' + props.src;
                    isReady.value = true;
                })
                .catch(error => $message.error(error));
        };
        const unistall = () => {
            //videoDOM.value?.parentNode?.removeChild(videoDOM.value);
        };
        onMounted(() => {
            init();
        });
        onBeforeUnmount(() => {
            unistall();
        });
        //功能块，定义额外按钮
        const showExtraSetting: Ref<string> = ref('');
        const extraButton: Ref<ExtraButton[]> = ref([
            {
                operate: 'speed',
                icon: '',
                text: '速度',
                showText: () => '' + status.speed.toFixed(1) + 'x',
                click: () => control('speed'),
                mouseenter() {
                    showExtraSetting.value = 'speed';
                },
                mouseleave() {
                    showExtraSetting.value = '';
                },
                childEvent(event, thing) {
                    //
                },
                childTemplate: TemplateSpeed,
            },
            {
                operate: 'volume',
                icon: () =>
                    status.isMute
                        ? SvgVolumeMute
                        : status.volume >= 0.67
                        ? SvgVolume
                        : SvgVolumeLite,
                text: '音量',
                click: () => control('toggleMute'),
                mouseenter() {
                    showExtraSetting.value = 'volume';
                },
                mouseleave() {
                    showExtraSetting.value = '';
                },
                childEvent(event, thing) {
                    //alert(123);
                },
                childTemplate: TemplateVolume,
            },
            {
                operate: 'fullscreen',
                icon: () => (status.isFullscreen ? SvgMinimize : SvgMaximize),
                text: () => (status.isFullscreen ? '取消全屏' : '全屏'),
                click: () => control('toggleFullscreen'),
            },
        ]);
        const receiveControl = (operate: string, options: any) => {
            control(operate, options);
        };
        //功能块，监听DOM的全屏状态
        (() => {
            const fullScreenChangeEvent = () => {
                if (document.fullscreenElement) {
                    status.isFullscreen = true;
                } else {
                    status.isFullscreen = false;
                }
            };
            const fullScreenChangeText = [
                'fullscreenchange',
                'mozfullscreenchange',
                'MSFullscreenChange',
                'msFullscreenChange',
                'webkitfullscreenchange',
            ];
            onMounted(() => {
                fullScreenChangeText.forEach(item =>
                    document.addEventListener(item, fullScreenChangeEvent)
                );
            });
            onBeforeUnmount(() => {
                fullScreenChangeText.forEach(item =>
                    document.removeEventListener(item, fullScreenChangeEvent)
                );
            });
            /*
            watch(
                () => {
                    const fsNode = videoDOM.value?.parentNode?.parentNode;
                    return !!(
                        fsNode?.fullscreen ||
                        fsNode?.mozFullScreen ||
                        fsNode?.webkitIsFullScreen ||
                        fsNode?.webkitFullScreen ||
                        fsNode?.msFullScreen
                    );
                },
                (newValue, oldValue) => {
                    console.log('监听到了');
                    if (newValue === oldValue) {
                        return;
                    }
                    status.isFullscreen = !!newValue;
                }
            );
            */
        })();
        //功能块，操作DOM实现进度条的实时渲染
        const durationDOM: Ref<HTMLDivElement | null> = ref(null);
        const [computedCurrent, computedGotoTime, computedCurrentSlide] =
            (() => {
                let isSlide = false;
                let slideW = '';
                const documentMouseUp = () => {
                    isSlide = false;
                    document.documentElement.removeEventListener(
                        'mouseup',
                        documentMouseUp
                    );
                };
                const computedCurrentSlide = (event: any, thing: string) => {
                    switch (thing) {
                        case 'down': {
                            //console.log('按下');
                            isSlide = true;
                            slideW = `${event?.layerX || 0}px`;
                            document.documentElement.addEventListener(
                                'mouseup',
                                documentMouseUp
                            );
                            break;
                        }
                        case 'move': {
                            if (isSlide) {
                                slideW = `${event?.layerX || 0}px`;
                            }
                            break;
                        }
                        case 'up': {
                            //console.log('松开');
                            documentMouseUp();
                            break;
                        }
                        default:
                            alert('无效');
                    }
                };
                return [
                    (): string => {
                        if (isSlide) {
                            return slideW;
                        }
                        if (!durationDOM.value || durationDOM === null) {
                            return '0px';
                        }
                        const allWidth = durationDOM.value.offsetWidth;
                        const progress = status.currentTime / status.totalTime;
                        return Math.round(allWidth * progress) + 'px';
                    },
                    (event: any): void => {
                        isSlide = false;
                        //console.log(event);
                        const curW = event?.layerX || 0;
                        const durW = durationDOM.value?.offsetWidth || -1;
                        const progress = curW / durW;
                        const currentTime = status.totalTime * progress;
                        control('gototime', currentTime);
                    },
                    computedCurrentSlide,
                ];
            })();
        //功能块，添加监听，下方控制栏显示/隐藏
        const isShowControl: Ref<boolean> = ref(false);
        const computedControlEvent = (() => {
            let timer: number | undefined = undefined;
            return (event: any, thing: string): void => {
                clearTimeout(timer);
                isShowControl.value = true;
                if (thing === 'leave') {
                    //isShowControl.value = false;
                    //return;
                }
                //console.log(event);
                if (Array.isArray(event.path)) {
                    if (
                        event.path.find((item: any) => {
                            if (typeof item === 'object' && item !== null) {
                                if (typeof item.getAttribute === 'function') {
                                    return !!item.getAttribute(
                                        'data-video-player-control-area'
                                    );
                                }
                            }
                        })
                    ) {
                        return;
                    }
                }
                timer = setTimeout(() => {
                    isShowControl.value = false;
                }, 2000);
                //console.log(thing);
            };
        })();
        //功能块，监听父组件给的指令
        watch(
            () => props.directive,
            (value: string) => {
                switch (value) {
                    case 'play':
                        control('play');
                        break;
                    case 'pause':
                        control('pause');
                        break;
                    default:
                }
            },
            { immediate: true }
        );
        //控制音乐播放器的开关
        onMounted(() => {
            store.commit('otherComponentControlMusicPlayerPause', true);
        });
        onBeforeUnmount(() => {
            store.commit('otherComponentControlMusicPlayerPause', false);
        });
        return {
            playerHash,
            componentDOM,
            status,
            videoUrl,
            videoDOM,
            isReady,
            isFirstPlay,
            isError,
            extraButton,
            control,
            formatTime,
            maskClick,
            durationDOM,
            computedCurrent,
            computedGotoTime,
            computedCurrentSlide,
            isShowControl,
            computedControlEvent,
            showExtraSetting,
            receiveControl,
        };
    },
});
