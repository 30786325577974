


















import { defineComponent, ref, watch } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import speedList from './speed-list';

if (Array.isArray(speedList)) {
    speedList.forEach(item => {
        if (isNaN(item)) {
            alert('程序出现了NaN错误');
        }
    });
}
export default defineComponent({
    props: {
        status: {
            type: Object,
        },
    },
    setup(props, { emit }) {
        const speed: Ref<number> = ref(1);

        const speedLevel = Array.isArray(speedList)
            ? speedList
            : [...[0.5, 0.8, 1, 1.2, 1.5, 2]];
        const setSpeed = (value: number) => {
            emit('receive', 'speed', value);
        };
        watch(
            () => props.status?.speed,
            newValue => (speed.value = newValue),
            { immediate: true }
        );

        return { speedLevel, speed, setSpeed };
    },
});
