
































import { defineComponent, Ref, ref, watch } from '@vue/composition-api';

export default defineComponent({
    props: {
        status: {
            type: Object,
        },
    },
    setup(props: any, { emit }) {
        //console.log('props', props);
        const volume: Ref<number> = ref(0.9);
        const isDown: Ref<boolean> = ref(false);
        const isMute: Ref<boolean> = ref(false);
        const controlDOM: Ref<null | HTMLDivElement> = ref(null);
        if (typeof props?.status?.volume !== 'undefined') {
            watch(
                () => props.status.volume,
                newValue => (volume.value = newValue),
                { immediate: true }
            );
            watch(
                () => props.status.isMute,
                newValue => (isMute.value = !!newValue),
                { immediate: true }
            );
        }
        const setVolume = (volume: number) => {
            //console.log('执行');
            emit('receive', 'volume', volume);
        };
        const getVolNum = (event: any): number => {
            let res = 0;
            const target = controlDOM.value;
            if (event && target !== null) {
                //console.log(event);
                const total = target.offsetHeight;
                //const current = total - event.offsetY;
                const current = (() => {
                    //console.log(
                    //    '元素top',
                    //    target.getBoundingClientRect().y,
                    //    '点击y',
                    //    event.y
                    //);
                    return total - (event.y - target.getBoundingClientRect().y);
                })();
                res = current / total;
            }
            return res;
        };
        return { isDown, isMute, controlDOM, volume, setVolume, getVolNum };
    },
});
